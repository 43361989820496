html {
  height: 100%;
  font-size: 87.5%;
}

body {
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  flex-direction: column;

  /* background-color: transparent; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    Poppins, 'Droid Sans', 'Helvetica Neue', sans-serif;
}

div#__next {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.react-pdf__Page__canvas {
  border-radius: 12;
}

@media screen and (width <= 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

/* Styling for PDF viewer component */

@media only screen and (width <= 600px) {
  .PDFContainer {
    flex: 0.8;
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding: 10px;
  }

  .PDFPage {
    height: auto !important;
    width: 100%;
  }

  .react-pdf__Page > canvas {
    /* max-width: 100%; */
    height: auto !important;
    margin: auto;
    border-radius: 12;
  }
}

@media screen and (width >= 601px) {
  .PDFContainer {
    flex: 0.8;
    height: 100%;
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding: 10px;
  }

  .PDFPage {
    height: auto !important;
    width: 100%;
  }

  .react-pdf__Page > canvas {
    height: auto !important;
    margin: auto;
    border-radius: 12;
  }
}

/* fix to hide resizeObserver warning from last pass */
:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}
