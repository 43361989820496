/* Style for the calendar wrapper */
.rmdp-calendar {
  border-radius: 8px; /* Example of rounding corners */
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%); /* Example of adding shadow */
  font-family: Poppins, sans-serif;
}

.rmdp-week-day {
  color: #555 !important;
}

.rmdp-day {
  color: #555 !important;
}

.rmdp-day.rmdp-selected span {
  background-color: #0c6ff9 !important;
}

.rmdp-range {
  background-color: #0c6ff9 !important;
}

.rmdp-day.rmdp-range span {
  color: #fff !important;
}

.rmdp-day.rmdp-today span {
  border-width: 1px !important;
  border-color: #555 !important;
  border-style: solid !important;
  color: #555 !important;
  background-color: inherit !important;
}

.rmdp-day.rmdp-disabled span {
  cursor: not-allowed !important;
}

.rmdp-day:hover span {
  background-color: #0c6ff9 !important;
  color: #fff;
}

.rmdp-day.rmdp-today.rmdp-range span {
  color: #fff !important;
}

.rmdp-day.rmdp-today:hover span {
  color: #fff !important;
  background-color: #0c6ff9 !important;
}

.rmdp-day.rmdp-today.rmdp-selected span {
  background-color: #0c6ff9 !important;
  color: #fff !important;
  border: none !important;
}

.ep-arrow.rmdp-ep-arrow.rmdp-ep-shadow {
  visibility: hidden !important;
}

.rmdp-arrow-container.rmdp-right {
  padding: 2px !important;
}

.rmdp-arrow-container.rmdp-left {
  padding: 2px !important;
}

.rmdp-arrow-container.rmdp-right.disabled {
  cursor: not-allowed !important;
}

.rmdp-arrow-container.rmdp-left.disabled {
  cursor: not-allowed !important;
}
