/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #0c6ff9;
  position: fixed;
  z-index: 100031;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #0c6ff9,
    0 0 5px #0c6ff9;
  opacity: 1;
  transform: rotate(3deg) translate(0, -4px);
}
